// src/components/TextTitle.js
import React from 'react';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

import {
  TEXT_APP_NAME_WITH,
  TEXT_WHAT_THIS_APP_DETAIL_1,
  TEXT_WHAT_THIS_APP_DETAIL_2,
} from '../consts/constText';

const TextTitle = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <>
      <Typography component="h1" variant="h5" align="center">
        {TEXT_APP_NAME_WITH}
      </Typography>
      <Grid
        container
        direction={isSmallScreen ? 'column' : 'row'}
        justifyContent="center"
        alignItems="center"
      >
        <Typography component="h1" variant="subtitle2" align="center">
          {TEXT_WHAT_THIS_APP_DETAIL_1}
        </Typography>
        {isSmallScreen ? null : <>&nbsp;</>}
        <Typography component="h1" variant="subtitle2" align="center">
          {TEXT_WHAT_THIS_APP_DETAIL_2}
        </Typography>
      </Grid>
    </>
  );
};

export default TextTitle;
