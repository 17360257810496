// src/components/SettingComponentCommonAddDetail.js
import React, { useEffect, useState } from 'react';

import { TextField, Button, Typography } from '@mui/material';

import {
  FONT_SIZE_SETTING_VALUE,
  FONT_SIZE_SETTING_ADD_DETAIL,
  COLOR_COMMON_RED,
} from '../consts/constCss';
import {
  TEXT_SETTING_ADD_DETAIL,
  TEXT_SETTING_NOT_SET,
  TEXT_SETTING_ADD_DETAIL_PLUS,
  TEXT_SETTING_ALERT_TEXT_LIMIT,
} from '../consts/constText';
import { is } from 'date-fns/locale';

// 補足のコンポーネント
export const SettingComponentCommonAddDetail = ({
  isEditing,
  inputAddDetail,
  setInputAddDetail,
  isTargetAddValueTooLong,
  setIsTargetAddValueTooLong,
  addDetailTextLimit,
  addDetailTextExample,
}) => {
  // inputAddDetailが存在する場合は初めからTextFieldを表示する
  const [isDetailVisible, setIsDetailVisible] = useState(
    inputAddDetail ? true : false
  );

  // isEditingがfalseになったときにisDetailVisibleを初期化する
  useEffect(() => {
    if (!isEditing) {
      setIsDetailVisible(inputAddDetail ? true : false);
    }
  }, [isEditing, inputAddDetail]);

  // ボタンがクリックされたときにisDetailVisibleを切り替える
  const handleClick = () => setIsDetailVisible((prev) => !prev);

  // isEditingがfalseでinputAddDetailが存在しない場合は何も表示しない
  if (!isEditing && !inputAddDetail) {
    return null;
  }

  return (
    <>
      {isEditing ? (
        isDetailVisible ? (
          <div style={{ paddingTop: '4px' }}>
            <Typography
              variant="caption"
              display="block"
              style={{
                fontSize: FONT_SIZE_SETTING_ADD_DETAIL,
                fontWeight: 'bold',
                marginLeft: '-8px',
                marginTop: '6px',
              }}
            >
              {TEXT_SETTING_ADD_DETAIL}
            </Typography>
            <>
              <TextField
                variant="outlined"
                fullWidth
                placeholder={addDetailTextExample}
                value={inputAddDetail || ''}
                InputProps={{
                  style: {
                    fontSize: FONT_SIZE_SETTING_VALUE,
                    marginBottom: '-3px', // 下線分の調整
                    padding: '5px 8px',
                    // marginLeft: '-8px',
                  },
                }}
                onChange={(e) => {
                  setInputAddDetail(e.target.value);
                  setIsTargetAddValueTooLong(
                    addDetailTextLimit
                      ? e.target.value.length > addDetailTextLimit
                      : false
                  );
                }}
                multiline
                minRows={2}
              />

              {/* 文字数制限アラート */}
              {isEditing && isDetailVisible && isTargetAddValueTooLong && (
                <Typography
                  color={COLOR_COMMON_RED}
                  fontSize={FONT_SIZE_SETTING_ADD_DETAIL}
                  marginTop="4px"
                >
                  {`${addDetailTextLimit}${TEXT_SETTING_ALERT_TEXT_LIMIT}`}
                </Typography>
              )}
            </>
          </div>
        ) : (
          <Button
            onClick={handleClick}
            style={{
              border: '1px solid black',
              color: 'black',
              fontSize: FONT_SIZE_SETTING_ADD_DETAIL,
              padding: '0px 10px',
              marginLeft: '-4px',
              marginTop: '10px',
              minWidth: '48px',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            {TEXT_SETTING_ADD_DETAIL_PLUS}
          </Button>
        )
      ) : (
        <>
          <Typography
            variant="caption"
            display="block"
            style={{
              fontSize: FONT_SIZE_SETTING_ADD_DETAIL,
              fontWeight: 'bold',
              marginLeft: '-8px',
              marginTop: '6px',
            }}
          >
            {TEXT_SETTING_ADD_DETAIL}
          </Typography>
          <div
            style={{
              marginTop: '4px',
              lineHeight: '18px', // 行間の設定
            }}
          >
            <Typography
              variant="standard"
              style={{
                fontSize: FONT_SIZE_SETTING_VALUE,
                whiteSpace: 'pre-wrap', // 改行
              }}
            >
              {inputAddDetail ? inputAddDetail : TEXT_SETTING_NOT_SET}
            </Typography>
          </div>
        </>
      )}
    </>
  );
};
