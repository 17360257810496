import { useEffect, useState } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import store from './redux/store';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from 'react-router-dom';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ja from 'date-fns/locale/ja';

import styled from '@emotion/styled';

import Header from './components/Header';

import LoginScreen from './screens/LoginScreen';
import AccountScreen from './screens/AccountScreen';
import SignUpScreen from './screens/SignUpScreen';

import {
  PATH_ACCOUNT,
  PATH_LOGIN,
  PATH_SIGNUP,
  PATH_OTHERS,
  PATH_ROOT,
  PATH_TOP,
  PATH_ROOT_OUT,
  PATH_KIBAN_DETAIL,
} from './consts/const';
import { COLOR_BACKGROUND_DARK_WHITE } from './consts/constCss';
import { onAuthStateChanged } from 'firebase/auth';
import {
  selectUserIsLogin,
  setIsLogin,
  setUserInfo,
} from './redux/userDataSlice';
import { auth, db } from './firebase';
import { doc, getDoc } from 'firebase/firestore';
import { updateFirebaseUserAtLastLogin } from './utils/updateFirebaseUserAtLastLogin';
import { Loading } from './commons/Loading';
import TopScreen from './screens/TopScreen';
import KibanDetailScreen from './screens/KibanDetailScreen';

// ログインしていない時はROOTに遷移
function PrivateRoute({ children }) {
  const isLogin = useSelector(selectUserIsLogin);
  return isLogin ? children : <Navigate to={PATH_ROOT} />;
}

const AppContainer = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const isLogin = useSelector(selectUserIsLogin);

  // ユーザーのログイン状態を監視しReduxに保存
  useEffect(() => {
    // ユーザーのログイン状態の変更を監視
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // ユーザーがログインしている場合
        dispatch(setIsLogin(true));

        // Firestoreからユーザー情報を取得してReduxに保存
        const docRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          // Dispatch action to save user data in Redux
          dispatch(setUserInfo(docSnap.data()));
          // user_at_last_loginを更新
          updateFirebaseUserAtLastLogin(docRef, docSnap.data());
        } else {
          console.error('No such document!');
        }
      } else {
        // ユーザーがログアウトしている場合
        dispatch(setIsLogin(false));
      }

      setIsLoading(false);
    });

    // コンポーネントのクリーンアップ時に監視を解除
    return unsubscribe;
  }, []);

  return (
    <Router>
      <Loading isLoading={isLoading} />
      <StyledApp className="app">
        <Header />
        <Routes>
          {/* 初期画面 */}
          <Route path={PATH_ROOT} element={<LoginScreen />} />
          {/* ログイン */}
          <Route path={PATH_LOGIN} element={<LoginScreen />} />
          {/* TODO ↓削除、外からアカウント作成見れないように */}
          <Route path={PATH_SIGNUP} element={<SignUpScreen />} />

          {/* 仮置き基板詳細ページ(基板IDのみ確認)、パスパラメータ:kibanIdを受け取る */}
          <Route path={PATH_KIBAN_DETAIL} element={<KibanDetailScreen />} />

          {/* ログイン状態でアクセスできるパス */}
          <Route
            path={PATH_TOP}
            element={
              <PrivateRoute>
                <TopScreen />
              </PrivateRoute>
            }
          />
          <Route
            path={PATH_ACCOUNT}
            element={
              <PrivateRoute>
                <AccountScreen />
              </PrivateRoute>
            }
          />
          {/* 全ての例外パスで下記スクリーンを描画 */}
          <Route path={PATH_OTHERS} element={<Navigate to={PATH_ROOT} />} />

          {/* TraceChainAmp配下以外はとりあえずROOTに、将来的には他のアプリに？ログインページに？ */}
          <Route path={PATH_ROOT_OUT} element={<Navigate to={PATH_ROOT} />} />
        </Routes>
      </StyledApp>
    </Router>
  );
};

function App() {
  return (
    <Provider store={store}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={ja}
        dateFormats={{
          monthAndYear: 'yyyy/MM',
          year: 'yyyy',
        }} // カレンダー左上の日付表示 年選択を○○年表示
        localeText={{
          previousMonth: '前月を表示', // < のツールチップ
          nextMonth: '次月を表示', // > のツールチップ
          cancelButtonLabel: 'キャンセル', // スマホ画面のCANCELボタン
          okButtonLabel: '選択', // スマホ画面のOKボタン
        }}
      >
        <AppContainer />
      </LocalizationProvider>
    </Provider>
  );
}

const StyledApp = styled.div`
  background-color: ${COLOR_BACKGROUND_DARK_WHITE};
  min-height: 100vh;
`;

export default App;
