import { MAX_IMAGE_SIZE_TEXT } from './const';

export const TEXT_APP_NAME = 'TraceChainAmp';
export const TEXT_APP_NAME_WITH = 'TraceChainAmp';
export const TEXT_WHAT_THIS_APP = `${TEXT_APP_NAME}とは`;

export const TEXT_WHAT_THIS_APP_DETAIL_1 = `プロセスのあらゆる情報をトレースして`;
export const TEXT_WHAT_THIS_APP_DETAIL_2 = `ダイヤモンド半導体の研究開発を促進したい`;

export const TEXT_AGREE_TO_TERMS_OF_SERVICE = '利用規約に同意して登録する。';
export const TEXT_AGREE_TO_TERMS_OF_SERVICE_AND_PRIVACY_POLICY = `利用規約 および プライバシーポリシー を確認し、同意しました。`;

export const TEXT_LOGIN = 'ログイン';
export const TEXT_LOGOUT = 'ログアウト';
export const TEXT_UPDATE = '更新';
// export const TEXT_DELETE = '削除';
export const TEXT_CANCEL = 'キャンセル';
// export const TEXT_SAVE = '保存';
// export const TEXT_ADD = '追加';
export const TEXT_CLOSE = '閉じる';
export const TEXT_EDIT = '編集';

export const TEXT_SIGN_UP = '新規ユーザー登録はこちら';

export const TEXT_REQUIRED = '必須';
export const TEXT_REQUIRED_OF_THIS = 'この項目は必須です。';
export const TEXT_NOT_VISIBLE_TO_OTHER_USERS =
  '他のユーザーには表示されません。';

// メニュー
export const TEXT_MENU_NEWS = 'お知らせ';
export const TEXT_MENU_ACCOUNT = 'アカウント';
export const TEXT_MENU_RECRUIT_MANAGEMENT = '募集管理';
export const TEXT_CREATE_RECRUIT = '募集を作成';

// アカウント画面
export const TEXT_SETTING_GENERAL = '一般';
export const TEXT_SETTING_WORK_ENVIRONMENT = '作業環境';
export const TEXT_SETTING_OTHER = 'その他';
export const TEXT_SETTING_CHANGE_ICON = 'アイコンを変更';
export const TEXT_SETTING_CHANGE_IMAGE = '画像を変更';
export const TEXT_SETTING_ADD_DETAIL = '補足事項';
export const TEXT_SETTING_ADD_DETAIL_PLUS = '補足事項を追加';
export const TEXT_SETTING_NOT_SET = '未設定';
export const TEXT_SETTING_ADD_NEW_TAG = '新規タグを追加';
export const TEXT_SETTING_POPULAR_TAGS = 'よく利用されるタグ';
export const TEXT_SETTING_IS_PRIVATE = '非公開';
export const TEXT_SETTING_IS_PUBLIC = '公開';
export const TEXT_SETTING_SAVE = '保存';

export const TEXT_SETTING_ALERT_TEXT_LIMIT = '文字以内で入力してください。';
export const TEXT_SETTING_ALERT_MAX_IMAGE_SIZE_ERROR = `画像のサイズは${MAX_IMAGE_SIZE_TEXT}以下にしてください。`;
export const TEXT_SETTING_ALERT_IMAGE_NOT_SET = `画像が未設定です。`;

// 募集作成画面
export const TEXT_SETTING_ADD_DETAIL_BASIC =
  '補足事項があれば記載してください。';

// テキスト入力で新規タグを追加
export const TEXT_RECRUIT_CREATE_EDITING = '編集中の項目があります。';
export const TEXT_RECRUIT_CREATE_EDITING_DETAIL_1 = `以下の項目が編集中です。`;
export const TEXT_RECRUIT_CREATE_EDITING_DETAIL_2 = `編集を終了してから保存してください。`;
export const TEXT_RECRUIT_CREATE_NOT_SET_REQUIRED =
  '未設定の必須項目があります。';
export const TEXT_RECRUIT_CREATE_NOT_SET_REQUIRED_DETAIL_1 = `以下の必須項目が設定されていません。`;
export const TEXT_RECRUIT_CREATE_NOT_SET_REQUIRED_DETAIL_2 = `必須項目を設定してから保存してください。`;
