// src/components/ScreenBaseComponentM.jsx
import React from 'react';

import { Box, Container, CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { COLOR_BACKGROUND_WHITE } from '../consts/constCss';

const ScreenBaseComponentM = ({ defaultTheme = createTheme(), children }) => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container
        component="main"
        maxWidth="sm"
        sx={{ height: '100vh', marginTop: 6 }}
      >
        <CssBaseline />
        <Box bgcolor={COLOR_BACKGROUND_WHITE} p={1}>
          <Box
            sx={{
              marginTop: 4,
              padding: '0 50px 30px 50px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {children}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default ScreenBaseComponentM;
