// src/screens/LoginScreen.jsx
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { login } from '../functions/login';

import { Button, TextField, Box } from '@mui/material';

import { PATH_TOP } from '../consts/const';
import { TEXT_LOGIN, TEXT_REQUIRED_OF_THIS } from '../consts/constText';

import TextTitle from '../components/TextTitle';
import ScreenBaseComponentM from '../components/ScreenBaseComponentM';
import { selectUserIsLogin } from '../redux/userDataSlice';

const LoginScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // 未ログイン状態で「募集を作成」ボタンを押して遷移してきた場合、ログイン後に「募集を作成」画面に遷移
  const location = useLocation();

  //ログイン済みでログイン画面に遷移した場合(ROOTに遷移した場合)はTOPに遷移
  const isLogin = useSelector(selectUserIsLogin);
  useEffect(() => {
    if (isLogin) {
      navigate(PATH_TOP);
    }
  }, [isLogin]);

  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
  });

  const [formErrors, setFormErrors] = useState({
    email: '',
    password: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));

    validate(name, value);
  };

  const validate = (name, value) => {
    let error = '';
    if (value === '') {
      error = TEXT_REQUIRED_OF_THIS;
    }

    setFormErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { email, password } = formValues;

    // Firebaseログイン
    await login(dispatch, navigate, email, password);
  };

  // TODO メールアドレスなどと定数に
  return (
    <ScreenBaseComponentM>
      <TextTitle />

      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="メールアドレス"
          name="email"
          autoComplete="email"
          autoFocus
          value={formValues.email}
          error={Boolean(formErrors.email)}
          helperText={formErrors.email}
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="パスワード"
          type="password"
          id="password"
          autoComplete="current-password"
          value={formValues.password}
          error={Boolean(formErrors.password)}
          helperText={formErrors.password}
          onChange={handleChange}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          disableElevation
          sx={{ mt: 3, mb: 2 }}
        >
          {TEXT_LOGIN}
        </Button>
      </Box>
    </ScreenBaseComponentM>
  );
};

export default LoginScreen;
