// src/functions/signUp.js
import { INITIAL_USER_INFO, PATH_ROOT } from '../consts/const';

import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../firebase';
import { doc, setDoc } from 'firebase/firestore';

export async function signUp(dispatch, navigate, formValues) {
  const email = formValues.email;
  const password = formValues.password;

  if (
    !email ||
    !password ||
    !formValues.userName ||
    !formValues.isAllowTermsOfService
  ) {
    return;
  }

  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    // 登録成功
    const user = userCredential.user;

    const userConf = INITIAL_USER_INFO(user, formValues);
    const userConfPublic = {
      uid: userConf.uid,
      user_name: userConf.user_name,
      user_icon: userConf.user_icon,
    };

    const userRef = doc(db, 'users', `${user.uid}`);
    const userPublicRef = doc(db, 'users_public_profiles', `${user.uid}`);

    try {
      await setDoc(userRef, userConf);
      await setDoc(userPublicRef, userConfPublic);
    } catch (error) {
      // Firestoreへの書き込みに失敗した場合、Firebase Authからユーザーを削除します。
      await user.delete();
      throw error; // エラーを再スローして、エラーメッセージを表示します。
    }

    // 初期画面に遷移
    navigate(PATH_ROOT);
  } catch (error) {
    // 登録失敗
    const errorCode = error.code;
    const errorMessage = error.message;

    // エラーコードに対応するメッセージを設定
    let displayMessage = '';
    switch (errorCode) {
      case 'auth/email-already-in-use':
        displayMessage = 'このメールアドレスはすでに登録済みです。';
        break;
      default:
        displayMessage = errorMessage;
    }
    alert(displayMessage);
  }
}
