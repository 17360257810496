// src/screens/TopScreen.jsx
import React, { useEffect, useState } from 'react';

import { Tabs, Tab, Grid } from '@mui/material';

import { TabPanel } from '../commons/TabPanel';
import { TEXT_SIGN_UP } from '../consts/constText';

import ScreenBaseComponentL from '../components/ScreenBaseComponentL';
import AccountSettingCommon from '../components/AccountSettingCommon';
import { getFirebaseChipsFormattedList } from '../utils/getFirebaseChips';
import { PATH_SIGNUP } from '../consts/const';
import CustomLink from '../commons/CustomLink';
import { FONT_SIZE_MEDIUM_1 } from '../consts/constCss';
import TextTitle from '../components/TextTitle';

import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';

const TopScreen = () => {
  return (
    <ScreenBaseComponentL title={'TopPage'}>
      準備中
      <Grid container>
        <Grid item onClick={() => navigate(PATH_SIGNUP)}>
          <CustomLink
            icon={SubdirectoryArrowRightIcon}
            size={FONT_SIZE_MEDIUM_1}
          >
            {TEXT_SIGN_UP}
          </CustomLink>
        </Grid>
      </Grid>
    </ScreenBaseComponentL>
  );
};

export default TopScreen;
