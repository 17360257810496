import React from 'react';
import { useParams } from 'react-router-dom';

function KibanDetailScreen() {
  let { kibanId } = useParams(); // URLからkibanIdを取得

  return (
    <div>
      <h2>基板ID: {kibanId}</h2>
    </div>
  );
}

export default KibanDetailScreen;
