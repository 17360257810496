// src/components/ScreenBaseComponentL.jsx
import React from 'react';

import { Box, Container, CssBaseline, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import {
  COLOR_BACKGROUND_DARK_WHITE,
  COLOR_BACKGROUND_WHITE,
  FONT_FAMILY,
} from '../consts/constCss';

const ScreenBaseComponentL = ({
  defaultTheme = createTheme(),
  title = null,
  children,
}) => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="90vh">
        <CssBaseline />

        <Box
          bgcolor={COLOR_BACKGROUND_DARK_WHITE}
          p={1}
          pb={5}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {title && (
            <Box sx={{ width: '100%', bgcolor: COLOR_BACKGROUND_WHITE }}>
              <Typography
                component="h1"
                variant="h5"
                style={{
                  padding: '20px 20px 5px 20px',
                  font: FONT_FAMILY,
                  fontWeight: 'bold',
                }}
              >
                {title}
              </Typography>
            </Box>
          )}
          <Box sx={{ width: '100%', bgcolor: COLOR_BACKGROUND_WHITE }}>
            {children}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default ScreenBaseComponentL;
