// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import {
  browserLocalPersistence,
  getAuth,
  setPersistence,
} from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
import firebaseConfig from './firebaseConfig';

// memo: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app, 'asia-northeast1');
const analytics = getAnalytics(app);

// ログイン状態をセッションに保持
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    // console.log("Firebase Auth persistence set to 'LOCAL'");
  })
  .catch((error) => {
    console.error('Error setting Firebase Auth persistence:', error);
  });

export { app, auth, db, storage, functions, analytics };
