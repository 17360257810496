// src/screens/SignUpScreen.jsx
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  Typography,
  FormHelperText,
} from '@mui/material';
import { signUp } from '../functions/signUp';
import TextTitle from '../components/TextTitle';
import { FONT_SIZE_MEDIUM_1 } from '../consts/constCss';

import {
  TEXT_AGREE_TO_TERMS_OF_SERVICE,
  TEXT_AGREE_TO_TERMS_OF_SERVICE_AND_PRIVACY_POLICY,
  TEXT_REQUIRED_OF_THIS,
} from '../consts/constText';

import ScreenBaseComponentM from '../components/ScreenBaseComponentM';

const SignUpScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // 未ログイン状態で「募集を作成」ボタンを押して遷移してきた場合、ログイン後に「募集を作成」画面に遷移
  const location = useLocation();

  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
    userName: '',
    isAllowTermsOfService: false,
  });

  const [formErrors, setFormErrors] = useState({
    email: '',
    password: '',
    userName: '',
    isAllowTermsOfService: '',
  });

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;

    // stateを更新。
    // チェックボックスの場合は、checkedの値、そうでない場合はvalueの値。
    setFormValues((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));

    // バリデーション関数を呼び出し。
    // チェックボックスの場合は、checkedの値、そうでない場合はvalueの値。
    validate(name, type === 'checkbox' ? checked : value);
  };

  // フォームのバリデーションを行う
  const validate = (name, value) => {
    let error = '';

    // valueが空文字か、nameが'isAllowTermsOfService'でvalueがfalse（チェックが入っていない）なら、エラーメッセージを設定
    if (value === '' || (name === 'isAllowTermsOfService' && value === false)) {
      error = TEXT_REQUIRED_OF_THIS;
    }

    // バリデーション結果（エラーメッセージ）をstateに保存
    setFormErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // すべてのフィールドをバリデーション
    Object.keys(formValues).forEach((field) => {
      validate(field, formValues[field]);
    });

    // すべてのフィールドが正しく入力され、利用規約に同意しているかを確認
    const isValidForm =
      Object.values(formErrors).every((error) => error === '') &&
      formValues.isAllowTermsOfService;

    // バリデーションに失敗した場合は処理を中断
    if (!isValidForm) {
      return;
    }

    //ユーザー登録処理
    signUp(dispatch, navigate, formValues);
  };

  // TODO ユーザー名などをconstにする

  return (
    <ScreenBaseComponentM>
      <TextTitle />
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit}
        sx={{
          mt: 3,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="userName"
              label="ユーザー名"
              name="userName"
              autoComplete="off"
              error={!!formErrors.userName}
              helperText={formErrors.userName}
              value={formValues.userName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="email"
              label="メールアドレス"
              name="email"
              autoComplete="email"
              error={!!formErrors.email}
              helperText={formErrors.email}
              value={formValues.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              name="password"
              label="パスワード"
              type="password"
              id="password"
              autoComplete="new-password"
              error={!!formErrors.password}
              helperText={formErrors.password}
              value={formValues.password}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="isAllowTermsOfService"
                  color="primary"
                  type="checkbox"
                  checked={formValues.isAllowTermsOfService}
                  onChange={handleChange}
                />
              }
              label={
                <Typography style={{ fontSize: FONT_SIZE_MEDIUM_1 }}>
                  {TEXT_AGREE_TO_TERMS_OF_SERVICE_AND_PRIVACY_POLICY}
                </Typography>
              }
            />
            {formErrors.isAllowTermsOfService && (
              <FormHelperText error>
                {formErrors.isAllowTermsOfService}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          disableElevation
          sx={{ mt: 3, mb: 2 }}
        >
          {TEXT_AGREE_TO_TERMS_OF_SERVICE}
        </Button>
      </Box>
    </ScreenBaseComponentM>
  );
};

export default SignUpScreen;
