// src/components/SettingComponentTextInput.js
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { TextField, Typography } from '@mui/material';

import {
  FONT_SIZE_SETTING_VALUE,
  FONT_SIZE_SETTING_ADD_DETAIL,
  COLOR_COMMON_RED,
} from '../consts/constCss';
import {
  TEXT_SETTING_NOT_SET,
  TEXT_SETTING_ALERT_TEXT_LIMIT,
} from '../consts/constText';

import { getValueByPath } from '../utils/getValueByPath';

import { SettingComponentBase } from './SettingComponentBase';
import { TEXT_LIMIT_NUM_STANDARD_1000 } from '../consts/const';

export const SettingComponentTextInput = ({
  title, // 要設定
  baseKey, // 要設定
  isAddDetail, // 補足事項、必要であればtrue
  addDetailTextLimit, // 補足事項の文字数、必要であれば数値、デフォルトでTEXT_LIMIT_NUM_ADD_DETAIL_1000
  addDetailTextExample, // 補足事項の例文、必要であればテキスト
  isRequired, // 必須か否か、必要であればtrue
  isViewPrivateText, // 他のユーザーには公開されません、必要であればtrue
  supplementText, // 補足事項のテキスト、必要であればテキスト
  isRequirePublicTypeSetting, // 公開範囲を設定する必要がある場合はtrue
  isCreatingRecruit, // (要設定)募集作成画面であればtrue

  children,
  targetTextLimit = TEXT_LIMIT_NUM_STANDARD_1000, // 要設定、文字数制限、未設定の場合はTEXT_LIMIT_NUM_STANDARD_1000
  multiline = false, // 要設定、複数行かどうか, trueの場合は行数を指定
  placeholderText = '', // プレースホルダーのテキスト、必要であればテキスト
}) => {
  // コンポーネントごとに固有
  const targetValueInitial = '';
  const [isTargetValueTooLong, setIsTargetValueTooLong] = useState(false);

  // 初期値
  const targetValue = useSelector((state) => {
    return getValueByPath(state.userData.userInfo, `${baseKey}.setting_value`);
  });
  const [inputTargetValue, setInputTargetValue] = useState(
    targetValue || targetValueInitial
  );

  // フラグ
  const [isEditing, setIsEditing] = useState(false);
  const [canUpdate, setCanUpdate] = useState(true);

  // コンポーネント独自
  const canUpdateOfChildren = () => {
    // 文字数制限を超えている場合は更新ボタンを押せない（コンポーネント独自）
    if (isTargetValueTooLong) {
      setCanUpdate(false);
    }
  };

  // コンポーネント独自
  const handleEditOfChildren = () => {
    setIsTargetValueTooLong(false); // 独自
  };

  // TODO_いつか：編集時の下線の色を変えたい
  return (
    <SettingComponentBase
      componentType="text"
      title={title}
      baseKey={baseKey}
      isAddDetail={isAddDetail}
      addDetailTextLimit={addDetailTextLimit}
      addDetailTextExample={addDetailTextExample}
      isRequired={isRequired}
      isViewPrivateText={isViewPrivateText}
      isCreatingRecruit={isCreatingRecruit}
      supplementText={supplementText}
      isRequirePublicTypeSetting={isRequirePublicTypeSetting}
      targetValue={targetValue}
      inputTargetValue={inputTargetValue}
      targetValueInitial={targetValueInitial}
      setInputTargetValue={setInputTargetValue}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      canUpdate={canUpdate}
      setCanUpdate={setCanUpdate}
      handleEditOfChildren={handleEditOfChildren}
      canUpdateOfChildren={canUpdateOfChildren}
    >
      {isEditing ? (
        <>
          {multiline ? (
            <TextField
              variant="outlined"
              fullWidth
              placeholder={placeholderText}
              value={inputTargetValue || ''}
              InputProps={{
                style: {
                  fontSize: FONT_SIZE_SETTING_VALUE,
                  marginBottom: '-3px', // 下線分の調整
                  padding: '5px 8px',
                },
              }}
              onChange={(e) => {
                setInputTargetValue(e.target.value);
                setIsTargetValueTooLong(
                  targetTextLimit
                    ? e.target.value.length > targetTextLimit
                    : false
                );
              }}
              multiline
              minRows={multiline}
            />
          ) : (
            <TextField
              variant="standard"
              fullWidth
              placeholder={placeholderText}
              value={inputTargetValue || ''}
              InputProps={{
                style: {
                  fontSize: FONT_SIZE_SETTING_VALUE,
                  marginBottom: '-3px', // 下線分の調整
                },
              }}
              onChange={(e) => {
                setInputTargetValue(e.target.value);
                setIsTargetValueTooLong(
                  targetTextLimit
                    ? e.target.value.length > targetTextLimit
                    : false
                );
              }}
            />
          )}

          {isTargetValueTooLong && (
            <Typography
              color={COLOR_COMMON_RED}
              fontSize={FONT_SIZE_SETTING_ADD_DETAIL}
              marginTop="4px"
            >
              {`${targetTextLimit}${TEXT_SETTING_ALERT_TEXT_LIMIT}`}
            </Typography>
          )}
        </>
      ) : (
        <>
          {/* 入力済みテキスト */}
          <Typography
            variant="standard"
            style={{
              fontSize: FONT_SIZE_SETTING_VALUE,
            }}
          >
            {/* TODOそのうち_本来であればlengthがなくても判定できるはず */}
            {/* {inputTargetValue ? inputTargetValue : TEXT_SETTING_NOT_SET} */}
            {inputTargetValue.length > 0
              ? inputTargetValue
              : TEXT_SETTING_NOT_SET}
          </Typography>
        </>
      )}

      {/* 追加のコンポーネント(必要なら) */}
      {children}
    </SettingComponentBase>
  );
};
