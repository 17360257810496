// src/redux/userDataSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const userDataSlice = createSlice({
  name: 'userData',
  initialState: {
    isLogin: false,
    userInfo: {},
  },
  reducers: {
    setIsLogin: (state, action) => {
      state.isLogin = action.payload;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
  },
});

export const { setIsLogin, setUserInfo } = userDataSlice.actions;

export const selectUserIsLogin = (state) => state.userData.isLogin;
export const selectUserInfo = (state) => state.userData.userInfo;

export default userDataSlice.reducer;
