// src/components/SettingComponentBase.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography, Grid } from '@mui/material';

import {
  FONT_SIZE_SETTING_TITLE,
  COLOR_BORDER,
  FONT_SIZE_SETTING_IS_PRIVATE,
  COLOR_COMMON_RED,
  FONT_SIZE_SETTING_ADD_DETAIL,
  COLOR_TEXT_COMMON_GRAY,
} from '../consts/constCss';
import {
  TEXT_NOT_VISIBLE_TO_OTHER_USERS,
  TEXT_REQUIRED,
  TEXT_SETTING_ADD_DETAIL_BASIC,
} from '../consts/constText';
import { SettingComponentCommonButtonEdit } from './SettingComponentCommonButtonEdit';
import { SettingComponentCommonAddDetail } from './SettingComponentCommonAddDetail';
import { getValueByPath } from '../utils/getValueByPath';
import { updateFirebaseAndReduxUserInfo } from '../utils/updateFirebaseAndReduxUserInfo';

import { _ } from '../consts/const';
import { TEXT_LIMIT_NUM_STANDARD_1000 } from '../consts/const';

export const SettingComponentBase = ({
  componentType,
  title,
  baseKey,
  isAddDetail,
  addDetailTextLimit = TEXT_LIMIT_NUM_STANDARD_1000,
  addDetailTextExample = TEXT_SETTING_ADD_DETAIL_BASIC,
  isRequired = false,
  isViewPrivateText,
  isCreatingRecruit = false,
  supplementText,
  isRequirePublicTypeSetting,

  targetValue,
  inputTargetValue,
  setInputTargetValue,
  targetValueInitial,

  isEditing,
  setIsEditing,
  canUpdate,
  setCanUpdate,

  handleEditOfChildren = () => {},
  canUpdateOfChildren = () => {},
  handleUpdateOfChildren = () => {},
  handleUpdateOfChildrenBefore = () => {
    return true;
  },

  children,
}) => {
  const dispatch = useDispatch();

  // 初期値
  const addDetailValue = useSelector((state) =>
    getValueByPath(state.userData.userInfo, `${baseKey}.add_detail`)
  );
  const publicType = useSelector((state) => {
    const value = getValueByPath(
      state.userData.userInfo,
      `${baseKey}.public_type`
    );
    return value !== null ? value : 'public';
  });

  // 入力値
  const [inputAddDetail, setInputAddDetail] = useState(addDetailValue || null);
  const [isTargetAddValueTooLong, setIsTargetAddValueTooLong] = useState(false);
  const [inputPublicType, setInputPublicType] = useState(publicType);

  const currentObj = {
    setting_value: targetValue || targetValueInitial,
    add_detail: addDetailValue,
    public_type: publicType,
    component_type: componentType,
  };
  const [newObj, setNewObj] = useState(currentObj);

  // 画面際読み込み時にデータを格納
  useEffect(() => {
    setInputAddDetail(addDetailValue);
  }, []);

  // 入力値が変更されたらnewObjに格納
  // 募集作成の場合、reduxにもデータを保持
  useEffect(() => {
    setNewObj({
      setting_value: inputTargetValue,
      add_detail: inputAddDetail ? inputAddDetail : null,
      public_type: inputPublicType ? inputPublicType : 'public',
      component_type: componentType,
    });
  }, [inputTargetValue, inputAddDetail, inputPublicType, isEditing]);

  // 更新ボタンの制御
  useEffect(() => {
    setCanUpdate(true);

    // 初期値が空かつ未入力の場合は更新ボタンを押せない
    if (
      !(
        (targetValue && !_.isEmpty(targetValue)) ||
        (inputTargetValue && !_.isEmpty(inputTargetValue))
      )
    ) {
      setCanUpdate(false);
    }

    // 初期値と更新値が同じ場合は更新ボタンを押せない
    if (_.isEqual(currentObj, newObj)) {
      setCanUpdate(false);
    }

    // 必須かつ未入力の場合は更新ボタンを押せない
    if (isRequired && inputTargetValue && inputTargetValue.length === 0) {
      setCanUpdate(false);
    }

    // 文字数制限を超えている場合は更新ボタンを押せない
    if (isTargetAddValueTooLong) {
      setCanUpdate(false);
    }

    // コンポーネント独自
    canUpdateOfChildren();
  }, [
    inputTargetValue,
    isTargetAddValueTooLong,
    inputAddDetail,
    inputPublicType,
    newObj,
  ]);

  const handleUpdate = async () => {
    const canUpdateByBefore = await handleUpdateOfChildrenBefore();

    if (!canUpdate || !canUpdateByBefore) {
      return;
    }

    await updateFirebaseAndReduxUserInfo(dispatch, baseKey, newObj);

    handleUpdateOfChildren();

    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setInputTargetValue(targetValue || targetValueInitial);
    setInputAddDetail(addDetailValue);
  };

  const handleEdit = () => {
    setIsEditing(true);
    setIsTargetAddValueTooLong(false);

    // コンポーネント独自
    handleEditOfChildren();
  };

  return (
    <Box mb={3}>
      {/* ボーダー */}
      <Box bgcolor={COLOR_BORDER} height={1.5} mb={4}></Box>

      {/* タイトル */}
      <Grid container alignItems="flex-start">
        <Grid item>
          <Typography
            variant="h6"
            style={{
              fontSize: FONT_SIZE_SETTING_TITLE,
              fontWeight: 'bold',
              display: 'flex',
            }}
          >
            {title}
            {/* isRequired=trueの場合、「必須」と赤文字で表示 */}
            {isRequired && (
              <span
                style={{
                  fontSize: FONT_SIZE_SETTING_IS_PRIVATE,
                  color: COLOR_COMMON_RED,
                  paddingLeft: '5px',
                  marginTop: '2px',
                }}
              >
                {TEXT_REQUIRED}
              </span>
            )}
          </Typography>
        </Grid>
        {isViewPrivateText && (
          <Grid item style={{ paddingLeft: '10px' }}>
            <Typography
              variant="h6"
              style={{
                fontSize: FONT_SIZE_SETTING_IS_PRIVATE,
                color: COLOR_COMMON_RED,
              }}
            >
              {TEXT_NOT_VISIBLE_TO_OTHER_USERS}
            </Typography>
          </Grid>
        )}
        {supplementText && (
          <Typography
            variant="standard"
            style={{
              fontSize: FONT_SIZE_SETTING_ADD_DETAIL,
              color: COLOR_TEXT_COMMON_GRAY,
              paddingLeft: '10px',
            }}
          >
            {supplementText}
          </Typography>
        )}
      </Grid>

      {/* フォーム */}
      <Grid container spacing={2} alignItems="center">
        <Grid
          item
          xs={12}
          sm={10}
          style={{ paddingRight: '10px', paddingLeft: '24px' }}
        >
          <div>
            {children}

            {/* 補足事項 */}
            {isAddDetail && (
              <SettingComponentCommonAddDetail
                isEditing={isEditing}
                inputAddDetail={inputAddDetail}
                setInputAddDetail={setInputAddDetail}
                addDetailTextLimit={addDetailTextLimit}
                isTargetAddValueTooLong={isTargetAddValueTooLong}
                setIsTargetAddValueTooLong={setIsTargetAddValueTooLong}
                addDetailTextExample={addDetailTextExample}
              />
            )}
          </div>
        </Grid>

        <>
          {/* ボタン */}
          <Grid item xs={12} sm={2}>
            <SettingComponentCommonButtonEdit
              isEditing={isEditing}
              handleUpdate={handleUpdate}
              handleCancel={handleCancel}
              handleEdit={handleEdit}
              canUpdate={canUpdate}
            />
          </Grid>
        </>
      </Grid>
    </Box>
  );
};
