// src/screens/AccountScreen.jsx
import React, { useEffect, useState } from 'react';

import { Tabs, Tab } from '@mui/material';

import { TabPanel } from '../commons/TabPanel';
import { TEXT_MENU_ACCOUNT, TEXT_SETTING_GENERAL } from '../consts/constText';

import ScreenBaseComponentL from '../components/ScreenBaseComponentL';
import AccountSettingCommon from '../components/AccountSettingCommon';
import { getFirebaseChipsFormattedList } from '../utils/getFirebaseChips';

const AccountScreen = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [initialChips, setInitialChips] = useState({});

  // Firebaseからchipsを取得
  useEffect(() => {
    const fetchChips = async () => {
      const sortedData = await getFirebaseChipsFormattedList();
      if (sortedData) {
        setInitialChips(sortedData);
      }
    };

    fetchChips();
  }, []);

  return (
    <ScreenBaseComponentL title={TEXT_MENU_ACCOUNT}>
      {/* タブ */}
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
      >
        <Tab label={TEXT_SETTING_GENERAL} />
      </Tabs>

      {/* タブの遷移先*/}
      <TabPanel value={value} index={0}>
        <AccountSettingCommon initialChips={initialChips} />
      </TabPanel>
    </ScreenBaseComponentL>
  );
};

export default AccountScreen;
