import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import {
  COLOR_TEXT_COMMON,
  COLOR_TEXT_COMMON_HOVERED,
  FONT_FAMILY,
  FONT_SIZE_MEDIUM_1,
} from '../consts/constCss';

const StyledLinkWrapper = styled(RouterLink)({
  textDecoration: 'none',
  color: COLOR_TEXT_COMMON,
  display: 'flex',
  alignItems: 'center',
  '&:visited': {
    color: COLOR_TEXT_COMMON,
  },
  '&:hover': {
    color: COLOR_TEXT_COMMON_HOVERED,
  },
});

const StyledTypography = styled(Typography)(({ theme, fontSize }) => ({
  fontSize,
  fontFamily: FONT_FAMILY,
  color: 'inherit',
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: 'inherit',
  paddingRight: '4px',
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

const CustomLink = ({
  to,
  children,
  icon: Icon,
  size = FONT_SIZE_MEDIUM_1,
}) => {
  const iconSize = `${parseFloat(size) * 1.5}`; // フォントサイズを1.5倍に

  return (
    <StyledLinkWrapper to={to}>
      {Icon && (
        <StyledIconButton disableRipple>
          <Icon style={{ fontSize: iconSize }} />
        </StyledIconButton>
      )}
      <StyledTypography fontSize={size}>{children}</StyledTypography>
    </StyledLinkWrapper>
  );
};

export default CustomLink;
