/**
 * ドット区切りのパスに従ってオブジェクトから値を取得します。
 *
 * @param {Object} obj 値を取得する元となるオブジェクト
 * @param {string} path ドット区切りのパス（例："user_info_detail.assistant"）
 * @returns {any} 指定されたパスに対応する値。パスが無効な場合はundefinedを返します。
 */
export const getValueByPath = (obj, path) => {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};
