// src/components/SettingComponentCommonButtonEdit.js
import React from 'react';

import { Button, Box } from '@mui/material';

import {
  COLOR_BUTTON_A,
  COLOR_BUTTON_GRAY,
  COLOR_BUTTON_FONT_BLACK,
} from '../consts/constCss';
import { TEXT_UPDATE, TEXT_CANCEL, TEXT_EDIT } from '../consts/constText';

export const SettingComponentCommonButtonEdit = ({
  isEditing,
  handleUpdate,
  handleCancel,
  handleEdit,
  canUpdate,
}) => {
  return (
    <Box display="flex" justifyContent="flex-end" flexWrap="nowrap">
      {isEditing ? (
        <Box display="flex" justifyContent="flex-end" flexWrap="nowrap">
          {/* 更新ボタン */}
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleUpdate}
            disabled={!canUpdate} // 必須項目で未入力の場合は更新ボタンを押せない(補足にはこの効果はかからない)
            style={{
              marginRight: '10px',
              color: canUpdate ? COLOR_BUTTON_A : COLOR_BUTTON_GRAY,
              borderColor: canUpdate ? COLOR_BUTTON_A : COLOR_BUTTON_GRAY,
            }}
          >
            {TEXT_UPDATE}
          </Button>
          {/* キャンセルボタン */}
          <Button
            variant="outlined"
            onClick={handleCancel}
            size="small"
            style={{
              color: COLOR_BUTTON_FONT_BLACK,
              borderColor: COLOR_BUTTON_GRAY,
            }}
          >
            {TEXT_CANCEL}
          </Button>
        </Box>
      ) : (
        //   編集ボタン
        <Button
          variant="outlined"
          size="small"
          onClick={handleEdit}
          style={{
            color: COLOR_BUTTON_FONT_BLACK,
            borderColor: COLOR_BUTTON_GRAY,
          }}
        >
          {TEXT_EDIT}
        </Button>
      )}
    </Box>
  );
};
